import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { Screen } from '@component/Screen';
import { Inject } from '@plugin/inversify';
import { SubscriptionsRepositoryType, TransactionStatus } from '../../contracts';
import { RadioOptionLabel } from '../../components/SubscriptionType/RadioOptionLabel';
let SubscriptionConflictModal = class SubscriptionConflictModal extends AbstractModal {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.subscription = null;
    }
    get description() {
        if (this.hasWaitingTransaction) {
            return this.$t('modules.subscription.conflicts.waiting.description');
        }
        if (this.isPaid) {
            return this.$t('modules.subscription.conflicts.isPaid.description');
        }
        return this.$t('modules.subscription.conflicts.unfinished.description', {
            startedAt: this.subscription ? this.subscription.startedAt : ''
        });
    }
    get hasWaitingTransaction() {
        return !!this.subscription && !!this.subscription.transaction && this.subscription.transaction.status === TransactionStatus.Pending;
    }
    get heading() {
        if (this.hasWaitingTransaction) {
            return this.$t('modules.subscription.conflicts.waiting.heading');
        }
        if (this.isPaid) {
            return this.$t('modules.subscription.conflicts.isPaid.heading');
        }
        return this.$t('modules.subscription.conflicts.unfinished.heading');
    }
    get isPaid() {
        return !!this.subscription && this.subscription.isPaid;
    }
    goToStatus() {
        var _a;
        if (!this.subscription) {
            this.$router.push('/');
            return;
        }
        this.$router.push({
            name: 'subscription.status',
            params: { term: this.subscription.id, transaction: `${(_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.token}` }
        });
        this.close();
    }
    goToProfile() {
        this.$router.push({ name: 'profile.subscription' });
        this.close();
    }
    onContinue() {
        if (!this.subscription) {
            return;
        }
        this.$router.push({ name: 'subscription.choose.payment', params: { term: this.subscription.id } });
        this.close();
    }
    onForce() {
        if (this.payload && typeof this.payload.onForce === 'function') {
            this.payload.onForce();
            this.close();
        }
    }
    async loadPeriod() {
        try {
            this.subscription = await this.subscriptions.load(this.payload.id);
            this.isLoading = false;
        }
        catch (e) {
            this.$logger(e, 'warn');
        }
    }
};
__decorate([
    Inject(SubscriptionsRepositoryType)
], SubscriptionConflictModal.prototype, "subscriptions", void 0);
SubscriptionConflictModal = __decorate([
    Component({
        name: 'SubscriptionConflictModal',
        components: { RadioOptionLabel, Screen },
        created() {
            this.loadPeriod();
        }
    })
], SubscriptionConflictModal);
export { SubscriptionConflictModal };
export default SubscriptionConflictModal;
